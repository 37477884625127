module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":["gatsby-remark-code-titles",{"resolve":"gatsby-remark-autolink-headers","options":{"offsetY":"100"}},{"resolve":"gatsby-remark-external-links","options":{"target":"_blank","rel":"nofollow noopener noreferrer"}},{"resolve":"gatsby-remark-images-contentful","options":{"maxWidth":1200,"linkImagesToOriginal":false,"wrapperStyle":"margin: auto;"}}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images-medium-zoom/gatsby-browser.js'),
      options: {"plugins":[],"background":"rgba(30,30,30,0.8)"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/src/intl","languages":["en","ja"],"defaultLanguage":"en","redirect":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Ayumi Takahashi - Freelance UI/UX Designer","short_name":"ayumitk.com","start_url":"/","background_color":"#FF5851","theme_color":"#FF5851","display":"minimal-ui","icon":"static/favicon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-121956784-1","head":true,"respectDNT":true,"pageTransitionDelay":250},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
